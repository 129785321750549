<template>
  <el-table :data="transactions" style="width: 100%">
    <el-table-column :label="$t('column.date')">
      <template slot-scope="scope">
        {{ instant.formatDate(scope.row.transaction.datetime) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('column.paymentType')">
      <template slot-scope="scope">
        {{ scope.row.payment_info.title }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('column.amount')">
      <template slot-scope="scope">
        <span v-if="scope.row.transaction.category === 'expense'">-</span
        >{{
          addCurrencySymbol(
            Number(scope.row.transaction.original_price),
            scope.row.transaction.original_currency
          )
        }}
      </template>
    </el-table-column>
    <el-table-column :label="`Account Last 5 Digits`">
      <template slot-scope="scope">
        {{ scope.row.transaction.account_last_five || "-" }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('transaction.form.file')">
      <template slot-scope="scope">
        <el-image
          v-if="scope.row.transaction.file"
          style="width: 50px; height: 50px"
          :src="scope.row.transaction.file"
          :preview-src-list="[scope.row.transaction.file]"
        >
        </el-image>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('column.remark')">
      <template slot-scope="scope">
        {{ scope.row.transaction.remark || "-" }}
      </template>
    </el-table-column>
    <el-table-column :label="`Editor`">
      <template slot-scope="scope">
        <!-- {{ scope.row.remark_by_user || '-' }} -->
        <span v-if="scope.row.transaction.remark_by_user">
          {{
            userMethods.displayName(
              scope.row.transaction.remark_by_user.first_name,
              scope.row.transaction.remark_by_user.last_name
            )
          }}
        </span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column
      v-if="isRoleAdmin()"
      :label="$t('column.action')"
      width="100"
    >
      <template slot-scope="scope">
        <router-link
          :to="
            `/balance/parent/${parentUserId}/balanceTransaction/${scope.row.id}`
          "
        >
          <i class="fa fa-eye action-icon" />
        </router-link>
        <span @click="() => $emit('handleDeleteTransaction', scope.row.id)">
          <i class="fas fa-trash-alt warning-icon" />
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { instant, user } from "@ivy-way/material";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";

export default {
  mixins: [moneyMixin, roleMixin],
  props: {
    parentUserId: {
      type: Number,
      default: null
    },
    transactions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  }
};
</script>

<style scoped></style>
