<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: userId } }"
          >
            {{ userName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ $t("pageTitle.Balance") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ `${userName || ""}${$t("balance.parentsBalance")}` }}</h1>
    <div class="summary">
      <h4>
        {{ $t("balance.title") }}
        <router-link
          v-if="isRoleAdmin()"
          :to="{ name: 'FinanceAdd', query: { parent: $route.query.username } }"
        >
          <i class="fas fa-plus" />
        </router-link>
      </h4>
      <h2>{{ isZeroAndDoNotDisplay(balance) }}</h2>
    </div>
    <template v-if="transactions">
      <BalanceTransactions
        :transactions="transactions"
        :parentUserId="userId"
        @handleDeleteTransaction="handleDeleteTransaction"
      />
      <div class="text-center">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="changeTransactionPage"
          :total="transactionTotal"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BalanceTransactions from "./BalanceTransactions";
import roleMixin from "@/mixins/role";
import moneyMixin from "@/mixins/money";
import profileApi from "@/apis/profile";
import balanceApi from "@/apis/balance";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.Balance")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    BalanceTransactions
  },
  mixins: [roleMixin, moneyMixin],
  computed: {
    userName() {
      if (!this.basicInfo) return 0;
      return `${this.basicInfo.first_name} ${this.basicInfo.last_name}`;
    },
    userId() {
      if (!this.basicInfo) return 0;
      return this.basicInfo.id;
    },
    balance() {
      if (!this.basicInfo) return "";
      return this.addCurrencySymbol(
        Number(this.$store.getters["profile/getDisplayBalance"]),
        this.basicInfo.account_balance_currency
      );
    }
  },
  data() {
    return {
      basicInfo: null,
      transactions: null,
      transactionTotal: 0,
      pageSize: 15,
      currentPage: 1
    };
  },
  async created() {
    const { basic_info: basicInfo } = await this.getUser();
    this.basicInfo = basicInfo;

    if (this.basicInfo.all_roles.includes("parent")) {
      await this.$store.dispatch("currency/fetchExchangeRate");
      this.$store.dispatch("profile/calculateDisplayBalance", {
        parentId: this.basicInfo.id,
        balance: this.basicInfo.account_balance,
        balanceCurrency: this.basicInfo.account_balance_currency
      });
    }

    await this.getBalanceTransactions();
  },
  methods: {
    async getUser() {
      if (this.$route.query.username) {
        return await profileApi.getUserByName(this.$route.query.username);
      } else if (this.$route.query.userId) {
        return await profileApi.getUserData(this.$route.query.userId);
      }
    },
    async changeTransactionPage(page) {
      this.currentPage = page;
      await this.getBalanceTransactions();
    },
    async getBalanceTransactions() {
      const {
        data: transactions,
        total
      } = await balanceApi.getBalanceTransactionsByParent(this.basicInfo.id, {
        page: this.currentPage
      });

      this.transactions = transactions;
      this.transactionTotal = total;
    },
    async handleDeleteTransaction(transactionId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await balanceApi.deleteBalanceTransaction(transactionId);
        await this.getBalanceTransactions();
        this.$message.success(this.$t("message.delete_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    isZeroAndDoNotDisplay(balance) {
      // 2022-04-29
      // 顯示的 balance 為「儲值交易總額扣掉未付學費」的金額
      // 邏輯可查看 calculateDisplayBalance
      return balance === "$0 TWD" ? "0" : balance;
    }
  }
};
</script>

<style scoped>
.summary {
  padding: 28px 0;
  border: 2px solid #e2e2e2;
  margin: 10px auto;
  text-align: center;
}

.summary h2 {
  color: #42a16a;
}

.summary a {
  text-decoration: none;
}

::v-deep .el-pagination {
  margin: 20px 0px;
}
</style>
